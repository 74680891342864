import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PaymentComponent from './PaymentComponent';
import PaymentInquiry from './PaymentInquiry';
import PaymentRefund from './PaymentRefund';
import MasterCard from './MasterCard';
import MasterCardCheckout from './MasterCardCheckout';
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/master" element={<MasterCard />} />
        <Route path="/master-authorise" element={<MasterCardCheckout />} />
        <Route path="/payment" element={<PaymentComponent />} />
        <Route path="/inquiry" element={<PaymentInquiry />} />
        <Route path="/refund" element={<PaymentRefund />} />
      </Routes>
    </Router>
  );
}

export default App;
