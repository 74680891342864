import React, { useEffect, useState } from 'react';
import axios from 'axios';

const MasterCard = () => {
  // useEffect(() => {
  //   console.log(responseHtml)

  // }, [responseHtml]);

  const encodeBase64 = (input) => {
    try {
        return btoa(input);
    } catch (e) {
        console.error('Error encoding to Base64', e);
        return null;
    }
};

  const sendRequest = async (url, data) => {
    try {
      const originalString = "merchant.TESTCBQ_KATRIA:901171c5770b0870b8a79e643d324958";
      const encodedString = encodeBase64(originalString);
      const config = {
        headers: {
          'Authorization': `Basic ${encodedString}`,
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'text/plain'
        }
      };
      const data={
        "apiOperation": "INITIATE_CHECKOUT",
        "interaction":{
            "operation" :"AUTHORIZE",
            "merchant": {
               "name": "KATRIA ONLINE TRADING"
            }
        },
        "order": {
            "currency":"QAR",
            "amount": "100",
            "id" : "101",
            "description": "Testing Payment Gateway"
        }
     }
      const newUrl = "https://test-cbq.mtf.gateway.mastercard.com/api/rest/version/72/merchant/TESTCBQ_KATRIA/session"
      const response = await axios.post(newUrl, data, config);

      console.log("Response Data:", response.data);

    } catch (error) {
      console.error("Error:", error);

      // if (error.response) {
      //   console.error("Error Response Data:", error.response.data);
      // }
    }
  };

  return (
    <div>
      <h1>Payment Page</h1>
      <button onClick={() => sendRequest()}>Payment Inquiry Request</button>
    </div>
  );
};

export default MasterCard;
