import React, { useState } from 'react';
import axios from 'axios';
const PaymentRefund = () => {
  const [responseHtml, setResponseHtml] = useState('');

  const sendRequest = async (url) => {
    try {
      console.log("Request URL:", url);
      const data={
        "testdata":""
      }
      const response = await axios.post(url, data, {
        headers: {   'Content-Type': 'application/json' }
      });

      console.log("Response Data:", response.data);

      // Assuming response.data is HTML, directly set it
      setResponseHtml(response.data);
      return response.data;
    } catch (error) {
      console.error("Error:", error);

      // If the error response is not in JSON format, log it appropriately
      if (error.response) {
        console.error("Error Response Data:", error.response.data);
      }
    }
  };

  const requestPaymentRefund = async () => {
    const url = 'http://localhost:3000/payment-refund';
    sendRequest(url);
  }


  return (
    <div>
      <h1>Payment Component</h1>
      <button onClick={() => requestPaymentRefund()}>Request Payment Refund</button>

      {responseHtml && (
        <div dangerouslySetInnerHTML={{ __html: responseHtml }} />
      )}
    </div>
  );
};

export default PaymentRefund;
