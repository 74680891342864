import React, { useEffect, useState } from 'react';
import axios from 'axios';

const MasterCardCheckout = () => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const checkout=async()=>{
    setTimeout(()=>{
      window.Checkout.configure({
        session:{
          id:inputValue
        }
      })
      window.Checkout.showPaymentPage();
    },2000)
  }

function errorCallback(error){
  console.log("errorcallback")
  console.log(error)
}

function cancelCallback(error){
  console.log("cancelcallback")
  console.log(error)
}
  
  return (
    <div>
      <h1>Payment Page</h1>
      <input 
        type="text" 
        value={inputValue} 
        onChange={handleInputChange} 
        placeholder="Type something..." 
      />
      <button onClick={() => checkout()}>Payment Inquiry Request</button>
      {JSON.stringify(inputValue)}
    </div>
  );
};

export default MasterCardCheckout;
