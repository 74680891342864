import React, { useEffect, useState } from 'react';
import axios from 'axios';
import sha256 from 'crypto-js/sha256';

const PaymentInquiry = () => {
  const [responseHtml, setResponseHtml] = useState('');
  const [OriginalPUN, setPun] = useState('1719487644332IXKgu');
  const Action = "14";
  const MerchantID = "KATRIA"
  const BankID = "QPAYPG02"
  const Lang = "En"
  const SecretKey = '2+cvR5HONv3Pxtt0';

  useEffect(() => {
    console.log(responseHtml)
    // if (responseHtml) {
    //   const form = document.querySelector('form');
    //   if (form) {
    //     form.submit();
    //   }
    // }
  }, [responseHtml]);

  const handleChange = (event) => {
    setPun(event.target.value);
  };

  const generatePayWebRequest = (
    SecretKey,
    Action,
    BankID,
    Lang,
    MerchantID,
    OriginalPUN
  ) => {
    const hash = hashPayWebRequest(
      SecretKey,
      Action,
      BankID,
      Lang,
      MerchantID,
      OriginalPUN
    );

    return {
      SecretKey,
      Action,
      BankID,
      Lang,
      MerchantID,
      OriginalPUN,
      SecureHash: hash,
    };
  };

  const hashPayWebRequest = (
    SecretKey,
    Action,
    BankID,
    Lang,
    MerchantID,
    OriginalPUN
  ) => {
    const varString = SecretKey + Action + BankID + Lang + MerchantID + OriginalPUN;

    return sha256Fun(varString);
  };

  const sha256Fun = (message) => sha256(message).toString();

  const sendRequest = async (url, data) => {
    try {
      console.log("Request URL:", url);
      console.log("Request Data:", data);
      const newUrl = "https://apiresponse.mamastycoon.com/payment-inquiry"
      const response = await axios.post(newUrl, data, {
        headers: { 'Access-Control-Allow-Origin': '*' }
      });

      console.log("Response Data:", response.data);

      // Assuming response.data is HTML, directly set it
      setResponseHtml(response.data);
      return response.data;
    } catch (error) {
      console.error("Error:", error);

      // If the error response is not in JSON format, log it appropriately
      if (error.response) {
        console.error("Error Response Data:", error.response.data);
      }
    }
  };


  const requestPaymentInquiry = async () => {
    const request = generatePayWebRequest(
      SecretKey,
      Action,
      BankID,
      Lang,
      MerchantID,
      OriginalPUN
    );

    const url = 'https://pguat.qcb.gov.qa/qcb-pg/api/gateway/2.0';
    sendRequest(url, request);
  }


  return (
    <div>
      <h1>Payment Component</h1>
      <input type="text" value={OriginalPUN} onChange={handleChange} />
      <button onClick={() => requestPaymentInquiry()}>Payment Inquiry Request</button>
    </div>
  );
};

export default PaymentInquiry;
