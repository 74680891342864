import React, { useEffect, useState } from 'react';
import axios from 'axios';

const PaymentComponent = () => {
  const [responseHtml, setResponseHtml] = useState('');
  useEffect(() => {
    if (responseHtml) {
      const form = document.querySelector('form');
      if (form) {
        form.submit();
      }
    }
  }, [responseHtml]);

  const sendRequest = async (url, data) => {
    try {
      const response = await axios.post(url, new URLSearchParams(data), {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      });
      console.log("response.data")
      setResponseHtml(response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const requestPayment = async () => {
    try {
      const data = {
        amount: 10,
        qty: 1,
        desc: "testing data for order"
      }
      const newUrl = "https://apiresponse.mamastycoon.com/payment"
      const response = await axios.post(newUrl, data, {
        headers: { 'Content-Type': 'application/json' }
      });
      const url = 'https://pguat.qcb.gov.qa/qcb-pg/api/gateway/2.0';
      sendRequest(url,  response.data);

    } catch (error) {
      console.error("Error:", error);
    }
  }

  return (
    <div>
      <h1>Payment Component</h1>
      <button onClick={() => requestPayment()}>Send Payment Request</button>
      {responseHtml && (
        <div dangerouslySetInnerHTML={{ __html: responseHtml }} />
      )}
    </div>
  );
};

export default PaymentComponent;
